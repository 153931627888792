const owlCarouselNavigationText = ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>']
const alreadyRequested = []

/**
 * Initialize (owl) carousel on given products grid
 * @param  {DOM Node} productsGrid <ul> with products
 * @param {Array} itemsCustom
 */

const productsGridToCarousel = (productsGrid, itemsCustom = [[0, 1], [768, 2], [1200, 2]]) => {
  jQuery(productsGrid).owlCarousel({
    itemsCustom: itemsCustom,
    navigation: true,
    scrollPerPage: true,
    theme: false,
    navigationText: owlCarouselNavigationText
  })
}

/**
 * Start default product carousel(s)
 */
const initCarousels = () => {
  const grid1 = document.querySelector('#store_blocks ul')
  const grid2 = document.querySelector('.banners__bottom ul')

  if (grid1) {
    productsGridToCarousel(grid1, [[0, 1], [768, 2], [1200, 2]])
  }
  if (grid2) {
    productsGridToCarousel(grid2, [[0, 1], [768, 3], [1200, 4]])
  }
}

jQuery(() => {
  initCarousels()
})
